import {Routes} from '@angular/router';
import {GuestGuard, LoggedInGuard} from '@ff/angular-core';
import {LoginComponent} from './components/auth/login/login.component';
import {LogoutComponent} from './components/auth/logout/logout.component';
import {PortfolioComponent} from './components/portfolio/portfolio.component';
import {UsersComponent} from "./components/users/users.component";
import {CustomerDetailsComponent} from "./components/customer-details/customer-details.component";
import {CustomerCardComponent} from "./components/customer-card/customer-card.component";
import {CustomerListComponent} from "./components/customer-list/customer-list.component";
import {PortfolioStatsComponent} from "./components/portfolio-stats/portfolio-stats.component";
import {CustomerCallsComponent} from "./components/customer-calls/customer-calls.component";
import {CustomerAssistanceComponent} from "./components/customer-assistance/customer-assistance.component";
import {NodesComponent} from "./components/nodes/nodes.component";
import {PortfolioClientsComponent} from "./components/portfolio-clients/portfolio-clients.component";
import {NodeClientsComponent} from "./components/node-clients/node-clients.component";
import {NodeStatsComponent} from "./components/node-stats/node-stats.component";
import {FavorisComponent} from "./components/favoris/favoris.component";

export const routes: Routes = [
    // Auth routes
    {
        path: 'auth',
        children: [
            {path: 'login', component: LoginComponent, canActivate: [GuestGuard]},
            {path: 'logout', component: LogoutComponent, canActivate: [LoggedInGuard]},
        ]
    },
    {path: 'users', component: UsersComponent, canActivate: [LoggedInGuard]},
    {path: '', component: LoginComponent},

    {
        path: 'portfolio', component: PortfolioComponent, canActivate: [LoggedInGuard],
        children: [
            {path: '', redirectTo: 'stats', pathMatch: "full"},
            {path: 'stats', component: PortfolioStatsComponent, canActivate: [LoggedInGuard]},
            {path: 'clients', component: PortfolioClientsComponent, canActivate: [LoggedInGuard]},
        ]
    },
    {path: 'clients', component: CustomerListComponent, canActivate: [LoggedInGuard]},
    {
        path: 'clients/:id', component: CustomerCardComponent, canActivate: [LoggedInGuard],
        children: [
            {path: '', redirectTo: 'information', pathMatch: "full"},
            {path: 'information', component: CustomerDetailsComponent, canActivate: [LoggedInGuard]},
            {path: 'calls', component: CustomerCallsComponent, canActivate: [LoggedInGuard]},
            {path: 'assistance', component: CustomerAssistanceComponent, canActivate: [LoggedInGuard]},
        ]
    },
    {
        path: 'nodes', component: NodesComponent, canActivate: [LoggedInGuard],
        children: [
            {path: '', redirectTo: 'stats', pathMatch: "full"},
            {path: 'stats', component: NodeStatsComponent, canActivate: [LoggedInGuard]},
            {path: 'clients', component: NodeClientsComponent, canActivate: [LoggedInGuard]},
        ]
    },
    {path: 'favoris', component: FavorisComponent, canActivate: [LoggedInGuard]},
];
