import {Component, Input, OnInit} from '@angular/core';
import {fromStateSearch} from "../../enums/fromStateSearch";
import {StatService} from "../../services/stat.service";

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {

    @Input() from: fromStateSearch = null;

    colorScheme = {
        domain: ['#5AA454', '#B00429', '#C7B42C', '#134291', "#694191"]
    };

    constructor(public statService: StatService) {
    }

    ngOnInit() {

    }

}
