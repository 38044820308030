import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {CustomerService} from "./customer.service";
import {LoaderService} from "@ff/angular-core";
import {MatSnackBar} from "@angular/material";
import {PortfolioService} from "./portfolio.service";
import {fromStateSearch} from "../enums/fromStateSearch";
import {NodeService} from "./node.service";

@Injectable()
export class SearchService {

    private _search: string = '';
    private _isSearching: boolean = false;

    public constructor(private _router: Router,
                       private _customerService: CustomerService,
                       private _nodeService: NodeService,
                       private _portfolioService: PortfolioService,
                       private _snackBar: MatSnackBar,
                       private _loaderService: LoaderService) {
    }

    launchSearch(searchValue: string) {
        this._search = searchValue;

        if (!this._isSearching){
            if (this._router.url !== '/clients'){
                this._loaderService.show();
                this._router.navigate(['/clients'])
            } else {
                this._isSearching = true;
                this._loaderService.show();
                this._customerService.index({
                    search: searchValue,
                    sort: '',
                    order: '',
                    page: '',
                    ratCom: '',
                    node: '',
                    from: fromStateSearch.SEARCH
                }).then(
                    (response: any) => {
                        this._isSearching = false;
                        this._loaderService.hide();
                        this._customerService.setTotalLength(response.meta.total);
                        this._customerService.setCustomers(response.data);
                    }
                ).catch(
                    (response) => {
                        this._isSearching = false;
                        this._loaderService.hide();
                        this._snackBar.open(response.error.message, 'Close', {
                            duration: 5000
                        });
                    }
                );
            }
        }
    }

    launchAutoComplete(searchValue: string)
    {
        return this._customerService.autoComplete({search: searchValue}).then(
            (response: any) => {
                return response;
            }
        ).catch(
            (response) => {
                this._snackBar.open(response.error.message, 'Close', {
                    duration: 5000
                });
            }
        );
    }

    getSearchValue() {
        return this._search;
    }

    getIsSearching(){
        return this._isSearching;
    }

    setIsSearching(value: boolean){
        this._isSearching = value;
    }

}
