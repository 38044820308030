import {Component, OnInit} from '@angular/core';
import {fromStateSearch} from "../../enums/fromStateSearch";
import {CustomerService} from "../../services/customer.service";
import {Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {MatSnackBar} from "@angular/material";
import {LoaderService} from "@ff/angular-core";

@Component({
    selector: 'app-favoris',
    templateUrl: './favoris.component.html',
    styleUrls: ['./favoris.component.scss']
})
export class FavorisComponent implements OnInit {

    public favoris: any;

    constructor(
        private _customerService: CustomerService,
        private _router: Router,
        public userService: UserService,
        private _snackBar: MatSnackBar,
        private _loaderService: LoaderService
    ) {
        this._loaderService.show();
        this.userService.favoris().then(response => {
            this._loaderService.hide();
            this.favoris = response;
        }).catch(
            (response) => {
                this._loaderService.hide();
                this._snackBar.open(response.error.message, 'Close', {
                    duration: 5000
                });
            }
        );
    }

    ngOnInit() {
    }

    goToClient(favori) {
        this._customerService.from = fromStateSearch.FAVORIS;
        this._router.navigate(['/clients', favori.numero_client]);
    }

    removeFavori(favori, index){
        this._loaderService.show();
        this._customerService.saveFavoris(favori.numero_client).then(response => {
            this._loaderService.hide();
            this.favoris.splice(index, 1);
        }).catch(
            (response) => {
                this._loaderService.hide();
                this._snackBar.open(response.error.message, 'Close', {
                    duration: 5000
                });
            }
        );
    }
}
