import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Injectable()
export class NodeService {

    public _endpoint: string;
    private _nodes: any;
    private _currentNode: any;
    private _nodeSearchForm: FormControl = new FormControl(null);

    public constructor(
        private formBuilder: FormBuilder,
        private _httpClient: HttpClient
    ) {
        this._endpoint = environment.api.host + '/nodes';
    }

    get nodeSearchForm(): FormControl {
        return this._nodeSearchForm;
    }

    get nodes(): any {
        return this._nodes;
    }

    set nodes(value: any) {
        this._nodes = value;
    }

    get currentNode(): any {
        return this._currentNode;
    }

    set currentNode(value: any) {
        this._currentNode = value;
    }

    public find(params: any): Promise<any> {
        return this._httpClient
            .get(this._endpoint + '/find', {
                params: params
            })
            .toPromise()
            .then((response: any) => response.data);
    }

    public autoComplete(params: any): Promise<any> {
        return this._httpClient
            .get(this._endpoint + '/autoComplete', {
                params: params
            })
            .toPromise()
            .then((response: any) => response.data);
    }

    public areNodesDifferent(value, node) {
        return value !== node.code && value !== node.name;
    }
}
