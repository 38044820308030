import {AuthService, UserService} from '@ff/angular-core';
import {Component, OnInit, Input} from '@angular/core';
import {MatSidenav} from '@angular/material';
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    apiUrl = environment.api.host;

    constructor() {
    }

    ngOnInit() {
    }


}
