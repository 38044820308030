import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable()
export class StatService {

    public _endpoint: string;

    private _stats: any = {};

    public constructor(
        private _httpClient: HttpClient) {
        this._endpoint = environment.api.host + '/stats';
    }

    getStats(from): any {
        return from ? this._stats[from] : null;
    }

    setStats(value: any, from) {
        this._stats[from] = value;
    }

    public index(params: any): Promise<any> {
        return this._httpClient
            .get(this._endpoint, {
                params: params
            })
            .toPromise()
            .then((response: any) => response.data);
    }
}
