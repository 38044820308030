import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {routes} from './app.routes';
import {API_CONFIG, AT_INTERNET_CONFIG, CoreComponentsModule, CoreServicesModule} from '@ff/angular-core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './components/header/header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {PortfolioComponent} from './components/portfolio/portfolio.component';
import {LoginComponent} from './components/auth/login/login.component';
import {LogoutComponent} from './components/auth/logout/logout.component';
import {ResetPasswordComponent} from './components/auth/reset-password/resetPassword.component';
import {ResetPasswordLinkComponent} from './components/auth/reset-password-link/resetPasswordLink.component';
// tslint:disable-next-line:max-line-length
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTreeModule
} from '@angular/material';
import {NavComponent} from './components/nav/nav.component';
import {FooterComponent} from './components/footer/footer.component';
import {NavService} from "./services/nav.service";
import {UsersComponent} from "./components/users/users.component";
import {UserService} from "./services/user.service";
import {UserDeleteDialogComponent} from "./components/dialogs/user/delete/delete.dialog.component";
import {UserEditDialogComponent} from "./components/dialogs/user/edit/edit.dialog.component";
import {CustomerDetailsComponent} from './components/customer-details/customer-details.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LocaleInterceptor} from "./interceptors/locale-interceptor";
import {CustomerListComponent} from './components/customer-list/customer-list.component';
import {CustomerCardComponent} from './components/customer-card/customer-card.component';
import {SearchService} from "./services/search.service";
import {CustomerService} from "./services/customer.service";
import {PortfolioStatsComponent} from './components/portfolio-stats/portfolio-stats.component';
import {HeaderWithNavComponent} from "./components/header-with-nav/header-with-nav.component";
import {PortfolioService} from "./services/portfolio.service";
import {CustomerCallsComponent} from './components/customer-calls/customer-calls.component';
import {CustomerAssistanceComponent} from './components/customer-assistance/customer-assistance.component';
import {NodesComponent} from './components/nodes/nodes.component';
import {NodeService} from "./services/node.service";
import {NodeClientsComponent} from './components/node-clients/node-clients.component';
import {PortfolioClientsComponent} from './components/portfolio-clients/portfolio-clients.component';
import {NodeStatsComponent} from './components/node-stats/node-stats.component';
import {FavorisComponent} from './components/favoris/favoris.component';
import {StatsComponent} from './components/stats/stats.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {StatService} from "./services/stat.service";


@NgModule({
    declarations: [
        AppComponent,
        PortfolioComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        LogoutComponent,
        ResetPasswordComponent,
        ResetPasswordLinkComponent,
        NavComponent,
        UsersComponent,
        UserDeleteDialogComponent,
        UserEditDialogComponent,
        HeaderWithNavComponent,
        CustomerDetailsComponent,
        CustomerListComponent,
        CustomerCardComponent,
        PortfolioStatsComponent,
        CustomerCallsComponent,
        CustomerAssistanceComponent,
        NodesComponent,
        NodeClientsComponent,
        PortfolioClientsComponent,
        NodeStatsComponent,
        FavorisComponent,
        StatsComponent
    ],
    entryComponents: [
        ResetPasswordComponent,
        ResetPasswordLinkComponent,
        UserDeleteDialogComponent,
        UserEditDialogComponent
    ],
    providers: [
        NavService,
        UserService,
        CustomerService,
        SearchService,
        PortfolioService,
        NodeService,
        StatService,
        {
            provide: API_CONFIG,
            useValue: {
                host: environment.api.host
            }
        },
        {
            provide: AT_INTERNET_CONFIG,
            useValue: {
                site: 595831,
                autosend: true
            }
        },
        {  provide: HTTP_INTERCEPTORS,
            useClass: LocaleInterceptor,
            multi: true
        }
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        MatCardModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatMenuModule,
        MatCheckboxModule,
        MatTreeModule,
        NgxChartsModule,
        CoreServicesModule,
        CoreComponentsModule,
        HttpClientModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    public constructor() {

    }

}
