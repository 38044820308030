import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerService} from "../../services/customer.service";
import {LoaderService} from "@ff/angular-core";
import {MatSnackBar} from "@angular/material";
import {SearchService} from "../../services/search.service";
import {fromStateSearch} from "../../enums/fromStateSearch";

@Component({
    selector: 'app-customer-card',
    templateUrl: './customer-card.component.html',
    styleUrls: ['./customer-card.component.scss']
})
export class CustomerCardComponent implements OnInit {

    public navRoutes = [
        {
            path: 'information',
            title: 'Fiche client',
        },
        {
            path: 'calls',
            title: 'Historique d\'appels',
        },
        {
            path: 'assistance',
            title: 'Demande d\'assistances en cours',
        }
    ];

    constructor(private _route: ActivatedRoute,
                public customerService: CustomerService,
                private _searchService: SearchService,
                private _snackBar: MatSnackBar,
                private _router: Router,
                private _loaderService: LoaderService) {
        this._loaderService.show();
        this.customerService.show(this._route.snapshot.params['id']).then(
            (response: any) => {
                this._searchService.setIsSearching(false);
                this._loaderService.hide();
                this.customerService.setCustomer(response);
            }
        ).catch(
            (response) => {
                this._searchService.setIsSearching(false);
                this._loaderService.hide();
                this._snackBar.open(response.error.message, 'Close', {
                    duration: 5000
                });
            }
        );
    }

    ngOnInit() {
    }

    goBack() {
        switch (this.customerService.from) {
            case fromStateSearch.SEARCH:
                this._router.navigate(['clients']);
                break;
            case fromStateSearch.PORTFOLIO:
                this._router.navigate(['portfolio/clients']);
                break;
            case fromStateSearch.NODES:
                this._router.navigate(['nodes/clients']);
                break;
            case fromStateSearch.FAVORIS:
                this._router.navigate(['favoris']);
                break;
        }
    }

}
