import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Injectable()
export class UserService {
    public _endpoint: string;

    public constructor(private _httpClient: HttpClient) {
        this._endpoint = environment.api.host + '/users';
    }

    public static getUserForm() {
        return new FormGroup({
            email: new FormControl('', [
                Validators.required
            ]),
            first_name: new FormControl('', [
                Validators.required
            ]),
            last_name: new FormControl('', [
                Validators.required
            ]),
            is_admin: new FormControl('', [

            ])
        });
    }

    public index(params: any): Promise<any> {
        return this._httpClient
            .get(this._endpoint, {
                params: params
            })
            .toPromise()
            .then((response: any) => response);
    }

    public store(form: FormGroup): Promise<any> {
        return this._httpClient
            .post(this._endpoint, form.getRawValue())
            .toPromise()
            .then((response: any) => response.data);
    }

    public show(id): Promise<any> {
        return this._httpClient
            .get(this._endpoint + '/' + id)
            .toPromise()
            .then((response: any) => response.data);
    }

    public update(id, form: FormGroup): Promise<any> {
        return this._httpClient
            .put(this._endpoint + '/' + id, form.getRawValue())
            .toPromise()
            .then((response: any) => response.data);
    }

    public delete(id): Promise<any> {
        return this._httpClient
            .delete(this._endpoint + '/' + id)
            .toPromise()
            .then((response: any) => response.data);
    }

    public favoris(): Promise<any> {
            return this._httpClient
                .get(this._endpoint + '/favoris')
                .toPromise()
                .then((response: any) => response.data);
    }
}



