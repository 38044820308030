import {Component, OnInit} from '@angular/core';
import {fromStateSearch} from "../../enums/fromStateSearch";

@Component({
    selector: 'app-portfolio-stats',
    templateUrl: './portfolio-stats.component.html',
    styleUrls: ['./portfolio-stats.component.scss']
})
export class PortfolioStatsComponent implements OnInit {

    public fromStateSearch = fromStateSearch;

    constructor() {
    }

    ngOnInit() {
    }

}
