import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort,MatTableDataSource} from "@angular/material";
import {CustomerService} from "../../services/customer.service";

@Component({
    selector: 'app-customer-assistance',
    templateUrl: './customer-assistance.component.html',
    styleUrls: ['./customer-assistance.component.scss']
})
export class CustomerAssistanceComponent implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    public displayedColumns: Array<any> = ['theme', 'date_creation', 'description', 'type', 'priorite', 'date_resolution'];
    dataSource;
    constructor(
        public customerService: CustomerService
    ) {
        this.dataSource = new MatTableDataSource(this.customerService.getCustomer().assistance);

    }

    ngOnInit() {
        this.dataSource.sort = this.sort;
    }
}
