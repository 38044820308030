import {HttpClient, HttpParams} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {UserService} from "../../services/user.service";
import {UserEditDialogComponent} from "../dialogs/user/edit/edit.dialog.component";
import {UserDeleteDialogComponent} from "../dialogs/user/delete/delete.dialog.component";

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    public displayedColumns = ['first_name', 'last_name', 'email', 'is_admin', 'created_at', 'actions'];
    public users: any = [];

    public resultsLength = 0;
    public isLoadingResults = true;
    public perPage: number = 10;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private http: HttpClient,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog,
                private _userService: UserService) {
    }

    ngOnInit() {

        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.search();
    }

    public search() {
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this._userService.index({
                        sort: this.sort.active || '',
                        order: this.sort.direction || '',
                        page: this.paginator.pageIndex || ''
                    });
                }),
                map(result => {
                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.resultsLength = result.meta.total;

                    return result.data;
                }),
                catchError((response) => {
                    this.isLoadingResults = false;
                    this._snackBar.open('Error', 'Close', {
                        duration: 3000
                    });
                    return observableOf([]);
                })
            ).subscribe(data => this.users = data);
    }

    public handleUser(user: any = null) {
        const dialogRef = this._dialog.open(UserEditDialogComponent, {
            data: user
        });

        dialogRef.afterClosed().subscribe(success => {
            if (success && !user) {
                this.search();
            }
        });
    }

    public deleteUser(user: any = null) {
        const dialogRef = this._dialog.open(UserDeleteDialogComponent, {
            data: user
        });

        dialogRef.afterClosed().subscribe(success => {
            if (success) {
                this.search();
            }
        });
    }
}
