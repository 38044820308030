import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@ff/angular-core';
import { Router, Event } from '@angular/router';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  @Input() public nav: MatSidenav;

  constructor(
    public userService: UserService,
    public router: Router,
  ) {
  }

  ngOnInit() {
  }

}
