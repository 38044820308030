import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SearchService} from "../../services/search.service";
import {CustomerService} from "../../services/customer.service";
import {LoaderService} from "@ff/angular-core";
import {MatPaginator, MatSnackBar, MatSort} from "@angular/material";
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from "@angular/router";
import {PortfolioService} from "../../services/portfolio.service";
import {fromStateSearch} from "../../enums/fromStateSearch";
import {NodeService} from "../../services/node.service";
import { ClientFlatNode } from  '../../treeUtilities/models/ClientFlatNode';

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Input() from: fromStateSearch = fromStateSearch.SEARCH;
    @Input() withHeader: boolean = true;
    @Input() columns = [
        {
            id: 'marche',
            name: 'MARCHÉ'
        },
        {
            id: 'region',
            name: 'RÉGION'
        },
        {
            id: 'RATCOM1',
            name: 'PORTEFEUILLE'
        },
        {
            id: 'noeud_1',
            name: 'NOEUD'
        },
        {
            id: 'numero_client',
            name: 'NUMÉRO CLIENT'
        }];
    @Input() containerCssClass = 'm-4';

    public redirectColumns = [/*'marche', 'region', 'RATCOM1',*/ 'noeud_1'];
    public customers: Array<any>;
    public displayedColumns: Array<any>;
    public perPage: number = 10;
    public selectedNode: ClientFlatNode;
    constructor(private _route: ActivatedRoute,
                private _router: Router,
                public customerService: CustomerService,
                private _portfolioService: PortfolioService,
                private _nodeService: NodeService,
                private _searchService: SearchService,
                private _snackBar: MatSnackBar,
                private _loaderService: LoaderService
    ) {
        this.customers = customerService.getCustomers();
    }

    ngOnInit() {
        this.paginator._intl.itemsPerPageLabel = "Eléments par page";
        this.displayedColumns = ['etablissement'].concat(this.columns.map(c => c.id)).concat(['access']);
        this.customerService.from = this.from;
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this._loaderService.show();
                    return this.customerService.index({
                        search: this._searchService.getSearchValue(),
                        sort: this.sort.active || '',
                        order: this.sort.direction || '',
                        page: this.paginator.pageIndex || '',
                        ratCom: this.from === fromStateSearch.PORTFOLIO ? this._portfolioService.currentTreeNode.name : '',
                        node: this.from === fromStateSearch.NODES ? this._nodeService.currentNode.code : '',
                        nodeNumber: this.from === fromStateSearch.NODES ? this._nodeService.currentNode.node_number : '',
                        from: this.from,
                        marcher: localStorage.getItem('marcher')
                    }).catch(
                        (response) => {
                            this._loaderService.hide();
                            this._snackBar.open(response.error.message, 'Close', {
                                duration: 5000
                            });
                        }
                    );
                }),
                map(result => {
                    // Flip flag to show that loading has finished.
                    this.customerService.setTotalLength(result.meta.total);

                    return result.data;
                }),
                catchError((response) => {
                    return observableOf([]);
                })
            ).subscribe(data => {
            this._loaderService.hide();
            this.customerService.setCustomers(data)
        });
    }

    exportClients() {
        this._loaderService.show();
        return this.customerService.export({
            search: this._searchService.getSearchValue(),
            sort: this.sort.active || '',
            order: this.sort.direction || '',
            page: this.paginator.pageIndex || '',
            ratCom: this.from === fromStateSearch.PORTFOLIO ? this._portfolioService.currentTreeNode.name : '',
            node: this.from === fromStateSearch.NODES ? this._nodeService.currentNode.code : '',
            from: this.from,
            marcher :  localStorage.getItem('marcher'),
            nodeNumber: (this._nodeService.currentNode && this._nodeService.currentNode.hasOwnProperty('node_number')) ? this._nodeService.currentNode.node_number : '',
        }).then(response => {
            this._loaderService.hide();
            let url = window.URL.createObjectURL(response);
            window.open(url);
        }).catch(
            (response) => {
                this._loaderService.hide();
                this._snackBar.open(response.error.message, 'Close', {
                    duration: 5000
                });
            }
        );
    }

    checkRedirect(columnId, value) {
        switch (columnId) {
            // case 'marche':
            // case 'region':
            // case 'RATCOM1':
            //     this._portfolioService.portfolioSearchForm.setValue(value);
            //     this._router.navigate(['portfolio/stats']);
            //     break;
            case 'noeud_1':
                this._nodeService.nodeSearchForm.setValue({"code": value});
                this._router.navigate(['nodes/stats']);
                break;
        }
    }
}
