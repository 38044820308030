import {AuthService, UserService} from '@ff/angular-core';
import {Component, OnInit} from '@angular/core';
import {NavService} from "../../services/nav.service";
import {FormControl, Validators} from "@angular/forms";
import {SearchService} from "../../services/search.service";
import {debounceTime, distinctUntilChanged, filter} from "rxjs/internal/operators";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public search: FormControl;
    public loadingAutoComplete: boolean = false;
    public showNoResultFound: boolean = false;
    searchResult = [];

    constructor(public userService: UserService,
                public authService: AuthService,
                private _navService: NavService,
                private _searchService: SearchService) {
        this.search = new FormControl('', [
            Validators.min(2)
        ]);
    }

    ngOnInit() {
        this.search.valueChanges
            .pipe(
                debounceTime(600),
                distinctUntilChanged(),
                filter((val: any) => val.length > 2))
            .subscribe(value => {
                if (!this._searchService.getIsSearching()){
                    this.loadingAutoComplete = true;
                    this.showNoResultFound = false;
                    this.searchResult = [];
                    this._searchService.launchAutoComplete(value).then(response => {
                        this.loadingAutoComplete = false;
                        this.searchResult = response;
                        if (response.length === 0) this.showNoResultFound = true;
                    })
                }
            });
    }

    launchSearch() {
        this._searchService.launchSearch(this.search.value);
    }

    initSearch() {
        this.search.setValue('');
        this.searchResult = [];
        this.showNoResultFound = false;
    }

    toggleNav() {
        this._navService.toggle();
    }
}
