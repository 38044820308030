import {Component, OnInit, ViewChild} from '@angular/core';
import {CustomerService} from "../../services/customer.service";
import {MatSort,MatTableDataSource} from "@angular/material";

@Component({
    selector: 'app-customer-calls',
    templateUrl: './customer-calls.component.html',
    styleUrls: ['./customer-calls.component.scss']
})
export class CustomerCallsComponent implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    public displayedColumns: Array<any> = ['motif', 'ouvert_le', 'description', 'statut'];
    dataSource;
    constructor(
        public customerService: CustomerService
    ) {
        this.dataSource = new MatTableDataSource(this.customerService.getCustomer().calls);
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;

    }

}
