import { FlatTreeControl } from '@angular/cdk/tree';
import {ClientFlatNode} from "./models/ClientFlatNode";

export class CustomTreeControl<T> extends FlatTreeControl<T> {

    /**
     * Iterate over each node in reverse order and expand each inferior level nodes until level 0.
     */
    expandParents(node: ClientFlatNode | any)
    {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        switch (node.level) {
            case 1:
                this.expand(this.getParent(node));
                break;
            case 2:
                let region: any = this.getParent(node);
                this.expand(region);
                region.index = this.dataNodes.indexOf(region);
                this.expand(this.getParent(region));
                break;
        }
    }

    getParent(node: ClientFlatNode | any) {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        for (let i = node.index; i >= 0; i--) {
            const currentNode = this.dataNodes[i];
            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
    }
}