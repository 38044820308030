import {Component, OnInit} from '@angular/core';
import {fromStateSearch} from "../../enums/fromStateSearch";
import {PortfolioService} from "../../services/portfolio.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-portfolio-clients',
    templateUrl: './portfolio-clients.component.html',
    styleUrls: ['./portfolio-clients.component.scss']
})
export class PortfolioClientsComponent implements OnInit {

    public fromStateSearch = fromStateSearch;
    public columns: Array<{id: string, name: string}> = [
        {
            id: 'numero_client',
            name: 'NUMÉRO CLIENT'
        },
        {
            id: 'noeud_1',
            name: 'NOEUD'
        },
    ];

    constructor(
        private _portfolioService: PortfolioService,
        private _router: Router
    ) {
        if (!this._portfolioService.currentTreeNode) {
            this._router.navigate(['portfolio/stats']);
        }
    }

    ngOnInit() {
    }

}
