import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatAutocompleteTrigger, MatSnackBar} from "@angular/material";
import {LoaderService} from "@ff/angular-core";
import {NodeService} from "../../services/node.service";
import {CustomerService} from "../../services/customer.service";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter} from "rxjs/internal/operators";
import {fromStateSearch} from "../../enums/fromStateSearch";
import {StatService} from "../../services/stat.service";

@Component({
    selector: 'app-nodes',
    templateUrl: './nodes.component.html',
    styleUrls: ['./nodes.component.scss']
})
export class NodesComponent implements OnInit {

    public navRoutes;
    public search: FormControl;
    public selectedNode: any;
    public loadingAutoComplete: boolean = false;
    public searchResult = [];
    public showNoResultFound: boolean = false;
    private loadingNode: boolean = false;

    constructor(private _route: ActivatedRoute,
                public nodeService: NodeService,
                private _customerService: CustomerService,
                private _statService: StatService,
                private _snackBar: MatSnackBar,
                private _router: Router,
                private _loaderService: LoaderService) {
        this.search = nodeService.nodeSearchForm;
        if (nodeService.nodeSearchForm.value && (!this.selectedNode
            || (this.selectedNode && this.nodeService.areNodesDifferent(nodeService.nodeSearchForm.value, this.selectedNode)))) {
            this.displayNode();
        } else if (this.nodeService.currentNode) {
            this.selectedNode = this.nodeService.currentNode;
            this.showClientsList();
        } else {
            this.hideClientsList();
        }
    }

    ngOnInit() {
        this.search.valueChanges
            .pipe(
                debounceTime(600),
                distinctUntilChanged(),
                filter((val: any) => val.length > 2))
            .subscribe(value => {
                this.launchSearch(value);
            });
    }

    launchSearch(value) {
        if (!this.loadingNode && !this.loadingAutoComplete) {
            this.loadingAutoComplete = true;
            this.showNoResultFound = false;
            this.searchResult = [];
            this.nodeService.autoComplete({search: value}).then(response => {
                this.loadingAutoComplete = false;
                this.searchResult = response;
                if (response.length === 0) this.showNoResultFound = true;
            }).catch(
                (response) => {
                    this.loadingAutoComplete = false;
                    this._loaderService.hide();
                    this._snackBar.open(response.error.message, 'Close', {
                        duration: 5000
                    });
                }
            );
        }
    }

    displayNode() {
        this.loadingNode = true;
        this._loaderService.show();
        this.nodeService.find({search: this.search.value.code, nodeNumber: this.search.value.node_number}).then(response => {
            this.selectedNode = response;
            this.nodeService.currentNode = response;
            this.showClientsList();
            this._router.navigate(['nodes/stats']);
            this._statService.index({
                node: response.code,
                nodeNumber: this.search.value.node_number,
                from: fromStateSearch.NODES
            }).then(response => {
                this.loadingNode = false;
                this._statService.setStats(response, fromStateSearch.NODES);
                this._loaderService.hide();
            }).catch(
                (response) => {
                    this.loadingNode = false;
                    this._loaderService.hide();
                    this._snackBar.open(response.error.message, 'Close', {
                        duration: 5000
                    });
                }
            );
        }).catch(
            (response) => {
                this.loadingNode = false;
                this._loaderService.hide();
                this._snackBar.open(response.error.message, 'Close', {
                    duration: 5000
                });
            }
        );
    }

    initView() {
        this.search.setValue('');
        this.selectedNode = null;
        this.nodeService.currentNode = null;
        this.searchResult = [];
        this.showNoResultFound = false;
        this.hideClientsList();
        this._router.navigate(['nodes/stats']);
    }

    private showClientsList() {
        this.navRoutes = [
            {
                path: 'stats',
                title: 'Stats',
            },
            {
                path: 'clients',
                title: 'Liste client',
            }
        ];
    }

    displayFn(node): string {
        return node ? node.name : node;
    }

    private hideClientsList() {
        this.navRoutes = [
            {
                path: 'stats',
                title: 'Stats',
            }
        ];
    }
}
