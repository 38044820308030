import {Injectable} from '@angular/core';
import {MatSidenav} from "@angular/material";

@Injectable()
export class NavService {

    private _nav: MatSidenav;

    public constructor() {
    }

    public setNav(nav: MatSidenav) {
        this._nav = nav;
    }

    public getNav(): MatSidenav {
        return this._nav;
    }

    public toggle() {
        this._nav.toggle();
    }

    isLaptop(size: number): boolean {
        return size > 768;
    }
}
