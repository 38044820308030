import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from "../../../../services/user.service";
import {LoaderService} from "@ff/angular-core";

@Component({
    selector: 'app-user-edit.dialog',
    templateUrl: './edit.dialog.html',
    styleUrls: ['./edit.dialog.scss']
})
export class UserEditDialogComponent {

    public userEditForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<UserEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _snackBar: MatSnackBar,
                private _loaderService: LoaderService,
                private _userService: UserService) {
        this.userEditForm = UserService.getUserForm();

        if (!data) {
            this.data = {};
            this.userEditForm.addControl('password', new FormControl('', [
                Validators.required
            ]));
            this.userEditForm.addControl('password_confirmation', new FormControl('', [
                Validators.required
            ]));
        }
    }

    submit() {
        this._loaderService.show();

        if (this.data.id) {
            this._userService.update(this.data.id, this.userEditForm).then(
                (user: any) => {
                    this._loaderService.hide();
                    this.dialogRef.close(true);
                }
            ).catch(
                (response) => {
                    this._loaderService.hide();
                    this._snackBar.open(response.error.message, 'Close', {
                        duration: 5000
                    });

                    const errors = response.error.errors;
                    Object.keys(errors).forEach((key) => {
                        this.userEditForm.controls[key].setErrors(errors[key]);
                    });
                }
            );
        } else {
            this._userService.store(this.userEditForm).then(
                (user: any) => {
                    this._loaderService.hide();
                    this.dialogRef.close(true);
                }
            ).catch(
                (response) => {
                    this._loaderService.hide();
                    this._snackBar.open(response.error.message, 'Close', {
                        duration: 5000
                    });

                    const errors = response.error.errors;
                    Object.keys(errors).forEach((key) => {
                        this.userEditForm.controls[key].setErrors(errors[key]);
                    });
                }
            );
        }
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }
}
