import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {UserService} from "../../../../services/user.service";
import {LoaderService} from "@ff/angular-core";


@Component({
  selector: 'app-user-delete.dialog',
  templateUrl: './delete.dialog.html',
  styleUrls: ['./delete.dialog.scss']
})
export class UserDeleteDialogComponent {

  constructor(public dialogRef: MatDialogRef<UserDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _snackBar: MatSnackBar,
              private _loaderService: LoaderService,
              private _userService: UserService) { }

  confirmDelete(): void {
      this._loaderService.show();

      this._userService.delete(this.data.id).then(
          (user: any) => {
              this._loaderService.hide();
              this.dialogRef.close(true);
          }
      ).catch(
          (response) => {
              this._loaderService.hide();
              this._snackBar.open(response.error.message, 'Close', {
                  duration: 5000
              });
          }
      );
  }
}
