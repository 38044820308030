import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {fromStateSearch} from "../enums/fromStateSearch";

@Injectable()
export class CustomerService {

    public _endpoint: string;
    private _customers: Array<any> = [];
    private _customer: any;
    private _totalLength: number = 0;
    private _from: fromStateSearch;

    public constructor(
        private _httpClient: HttpClient) {
        this._endpoint = environment.api.host + '/clients';
    }

    setCustomer(customer: any) {
        this._customer = customer;
    }

    getCustomer(){
        return this._customer;
    }

    setCustomers(customers: Array<any>) {
        this._customers = customers;
    }

    getCustomers(){
        return this._customers;
    }

    setTotalLength(nb: number) {
        this._totalLength = nb;
    }

    getTotalLength(){
        return this._totalLength;
    }

    get from(): fromStateSearch {
        return this._from;
    }

    set from(value: fromStateSearch) {
        this._from = value;
    }

    public index(params: any): Promise<any> {
        return this._httpClient
            .get(this._endpoint, {
                params: params
            })
            .toPromise()
            .then((response: any) => response);
    }

    public export(params: any): Promise<any> {
        return this._httpClient
            .get(this._endpoint + '/export', {
                params: params,
                responseType: "blob"
            })
            .toPromise()
            .then((response: any) => response);
    }

    public show(id): Promise<any> {
        return this._httpClient
            .get(this._endpoint + '/' + id)
            .toPromise()
            .then((response: any) => response.data);
    }

    public autoComplete(params: any): Promise<any> {
        return this._httpClient
            .get(this._endpoint + '/autoComplete', {
                params: params
            })
            .toPromise()
            .then((response: any) => response.data);
    }

    public saveFavoris(id): Promise<any> {
        return this._httpClient
            .get(this._endpoint + '/' + id + '/favoris')
            .toPromise()
            .then((response: any) => response.data);
    }
}
