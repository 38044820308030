export const environment = {
  production: true,
  api: {
    host: 'https://app-grana-mmt-d.mpshp.alzp.tgscloud.net/'
  },
  webpush : {
    applicationServerKey: null
  },
  oauth2: {
    digitalpass: {
      enabled: false,
      endpoint: '/oauth2/digitalpass'
    }
  }
};
