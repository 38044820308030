import {Component, Inject, ViewChild} from '@angular/core';
import {ATInternetService, AuthService, LoaderService, UserService} from '@ff/angular-core';
import {DOCUMENT} from '@angular/platform-browser';
import {MatSidenav} from "@angular/material";
import {NavService} from "./services/nav.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class AppComponent {

    @ViewChild('nav') nav: MatSidenav;

    public isLaptop: boolean = true;

    public navMode: 'side' | 'over' = 'side';

    public constructor(public userService: UserService,
                       public authService: AuthService,
                       private _navService: NavService,
                       private _loaderService: LoaderService,
                       @Inject(DOCUMENT) private _document: Document,
                       public atInternet: ATInternetService) {
        const bootloader = _document.getElementById('bootloader');
        bootloader.parentNode.removeChild(bootloader);
    }

    ngAfterViewInit() {
        this._navService.setNav(this.nav);
        this.isLaptop = this._navService.isLaptop(window.innerWidth);
        this.handleNavMode();
    }

    onResize(event) {
        (this.isLaptop = this._navService.isLaptop(event.target.innerWidth)) && this._navService.getNav() ? this._navService.getNav().open() : this._navService.getNav().close();
        this.handleNavMode();
    }

    handleNavMode() {
        this.navMode = this.isLaptop ? 'side' : 'over';
    }
}
