import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject} from "rxjs/index";
import {ClientNode} from "../treeUtilities/models/ClientNode";
import {ClientFlatNode} from "../treeUtilities/models/ClientFlatNode";
import {FormBuilder, FormControl} from "@angular/forms";

@Injectable()
export class PortfolioService {

    public _endpoint: string;
    private _tree: any;
    private _treeData: Array<any>;
    public dataChange = new BehaviorSubject<ClientNode[]>([]);
    private _currentTreeNode: ClientFlatNode;
    private _portfolioSearchForm: FormControl = new FormControl(null);

    get data(): ClientNode[] {
        return this.dataChange.value;
    }

    public constructor(
        private formBuilder: FormBuilder,
        private _httpClient: HttpClient) {
        this._endpoint = environment.api.host + '/portfolio';
    }

    public tree(): Promise<any> {
        return this._httpClient
            .get(this._endpoint + '/tree')
            .toPromise()
            .then((response: any) => response.data);
    }

    get portfolioSearchForm(): FormControl {
        return this._portfolioSearchForm;
    }

    get currentTreeNode(): ClientFlatNode {
        return this._currentTreeNode;
    }

    set currentTreeNode(value: ClientFlatNode) {
        this._currentTreeNode = value;
    }

    getTree() {
        return this._tree;
    }

    getTreeData() {
        return this._treeData;
    }

    initialize(treeData) {

        this._treeData = treeData;

        this.reload(treeData);
    }

    public updateTree(val: string)
    {
        const data = this.getTreeData();
        let filteredData = {};

        if (val){
            Object.keys(data).forEach(marche => {
                filteredData[marche] = {};
                Object.keys(data[marche]).forEach(region => {
                    filteredData[marche][region] = [];
                    data[marche][region].forEach(ratCom => {
                        if (ratCom.toLowerCase().includes(val.toLowerCase())){
                            filteredData[marche][region].push(ratCom);
                        }
                    });

                    if (filteredData[marche][region].length === 0 && region.toLowerCase().includes(val.toLowerCase())){
                        filteredData[marche][region] = data[marche][region];
                    } else if (filteredData[marche][region].length === 0 && !region.toLowerCase().includes(val.toLowerCase())){
                        delete filteredData[marche][region];
                    }
                });
                if (Object.keys(filteredData[marche]).length === 0 && marche.toLowerCase().includes(val.toLowerCase())){
                    filteredData[marche] = data[marche];
                } else if (Object.keys(filteredData[marche]).length === 0 && !marche.toLowerCase().includes(val.toLowerCase())){
                    delete filteredData[marche];
                }
            });
        } else {
            filteredData = data;
        }

        this.reload(filteredData);
    }

    reload(treeData) {
        const data = this.buildFileTree(treeData, 0);

        this._tree = data;

        // Notify the change.
        this.dataChange.next(data);
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `ClientNode`.
     */
    buildFileTree(obj: object, level: number): ClientNode[] {
        return Object.keys(obj).reduce<ClientNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new ClientNode();

            node.name = typeof value === 'object' ? key : value;

            if (value != null) {
                if (typeof value === 'object') {
                    node.children = this.buildFileTree(value, level + 1);
                }
            }

            return accumulator.concat(node);
        }, []);
    }
}
