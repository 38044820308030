import {Component, OnInit} from '@angular/core';
import {ATInternetService, LoaderService} from '@ff/angular-core';
import {MatSnackBar} from "@angular/material";
import {PortfolioService} from "../../services/portfolio.service";
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {Observable, of as observableOf} from 'rxjs';
import {ClientNode} from "../../treeUtilities/models/ClientNode";
import {ClientFlatNode} from "../../treeUtilities/models/ClientFlatNode";
import {Router} from "@angular/router";
import {CustomTreeControl} from "../../treeUtilities/CustomTreeControl";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {fromStateSearch} from "../../enums/fromStateSearch";
import {StatService} from "../../services/stat.service";

@Component({
    selector: 'app-home',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

    public navRoutes = [
        {
            path: 'stats',
            title: 'Stats',
        }
    ];

    public portfolioSearchForm: FormControl;

    public selectedNode: ClientFlatNode;

    treeControl: CustomTreeControl<ClientFlatNode>;
    treeFlattener: MatTreeFlattener<ClientNode, ClientFlatNode>;
    dataSource: MatTreeFlatDataSource<ClientNode, ClientFlatNode>;

    constructor(public atInternet: ATInternetService,
                private _portfolioService: PortfolioService,
                private _statService: StatService,
                private _router: Router,
                private _snackBar: MatSnackBar,
                private _loaderService: LoaderService)
    {
        this.portfolioSearchForm = this._portfolioService.portfolioSearchForm;

        this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
            this._isExpandable, this._getChildren);
        this.treeControl = new CustomTreeControl<ClientFlatNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.hideClientsList();
        this._portfolioService.dataChange.subscribe(data => this.dataSource.data = data);

        if (!this._portfolioService.getTree()){
            this._loaderService.show();
            this._portfolioService.tree().then(
                (response: any) => {
                    this._portfolioService.initialize(response);
                    if (this.portfolioSearchForm.value){
                        this._portfolioService.updateTree(this.portfolioSearchForm.value);
                    }
                    this._loaderService.hide();
                }
            ).catch(
                (response) => {
                    this._loaderService.hide();
                    this._snackBar.open(response.error.message, 'Close', {
                        duration: 5000
                    });
                }
            );
        } else if (this._portfolioService.currentTreeNode && this._portfolioService.getTree()) {
            this.treeControl.expandParents(this._portfolioService.currentTreeNode);
            this.selectedNode = this._portfolioService.currentTreeNode;
            if (this._portfolioService.currentTreeNode.level === 2) this.showClientsList();
        }
    }

    ngOnInit() {
        this.portfolioSearchForm.valueChanges.subscribe((val) => {
            this._portfolioService.updateTree(val);
        });
    }

    setCurrentTreeNode(node: ClientFlatNode, isParentNode)
    {
        let oldTreeNode = Object.assign({}, this._portfolioService.currentTreeNode);

        this.setCurrentNode(node);

        if (isParentNode && this._getLevel(node) === 0 && this.treeControl.isExpanded(node)) {
            this.treeControl.collapseAll();
            this.treeControl.expand(node);
        }

        if (node.name !== oldTreeNode.name)
            this.loadStats(this._portfolioService.currentTreeNode);

        isParentNode ? this.hideClientsList() : this.showClientsList();

        this._router.navigate(['portfolio/stats']);
    }

    setCurrentNode(node: ClientFlatNode)
    {
        this.selectedNode = node;
        this._portfolioService.currentTreeNode = node;
        this._portfolioService.currentTreeNode.index = this.treeControl.dataNodes.indexOf(node);
    }

    transformer = (node: ClientNode, level: number) => {
        return new ClientFlatNode(!!node.children, node.name, level);
    };

    private showClientsList(){
        this.navRoutes = [
            {
                path: 'stats',
                title: 'Stats',
            },
            {
                path: 'clients',
                title: 'Liste client',
            }
        ];
    }

    private hideClientsList(){
        this.navRoutes = [
            {
                path: 'stats',
                title: 'Stats',
            }
        ];
    }

    loadStats(node: ClientFlatNode)
    {
        const nodeInfos: any = this.getNodeInfos(node);
        this._loaderService.show();
        this._statService.index({
            ratCom: nodeInfos.ratCom,
            marche: nodeInfos.marche,
            region: nodeInfos.region,
            node: '',
            from: fromStateSearch.PORTFOLIO
        }).then(response => {
            this._statService.setStats(response, fromStateSearch.PORTFOLIO);
            this._loaderService.hide();
        }).catch(
            (response) => {
                this._loaderService.hide();
                this._snackBar.open(response.error.message, 'Close', {
                    duration: 5000
                });
            }
        );
    }

    getNodeInfos(node: ClientFlatNode)
    {
        let data = {};

        switch (node.level) {
            case 0:
                localStorage.setItem('marcher', node.name);
                data = {
                    marche: node.name,
                    region: '',
                    ratCom: '',
                };
                break;
            case 1:
                data = {
                    marche: this.treeControl.getParent(node).name,
                    region: node.name,
                    ratCom: '',
                };
                break;
            case 2:
                let region: ClientFlatNode = this.treeControl.getParent(node);
                region.index = this.treeControl.dataNodes.indexOf(region);
                const marche: ClientFlatNode = this.treeControl.getParent(region);
                data = {
                    marche: marche.name,
                    region: region.name,
                    ratCom: node.name,
                };
                break;
        }

        return data;
    }

    private _getLevel = (node: ClientFlatNode) => node.level;

    private _isExpandable = (node: ClientFlatNode) => node.expandable;

    private _getChildren = (node: ClientNode): Observable<ClientNode[]> => observableOf(node.children);

    hasChild = (_: number, _nodeData: ClientFlatNode) => _nodeData.expandable;

}
