import {Component, Input, OnInit} from '@angular/core';
import {CustomerService} from "../../services/customer.service";
import {MatSnackBar} from "@angular/material";
import {Router} from "@angular/router";
import {LoaderService} from "@ff/angular-core";

@Component({
    selector: 'app-header-with-nav',
    templateUrl: './header-with-nav.component.html',
    styleUrls: ['./header-with-nav.component.scss']
})
export class HeaderWithNavComponent implements OnInit {

    @Input() hasStar: boolean;
    @Input() title: string = '';
    @Input() headerClass: string = '';
    @Input() containerClass: string = '';
    @Input() navRoutes: Array<any>;

    constructor(
        public customerService: CustomerService,
        private _snackBar: MatSnackBar,
        private _loaderService: LoaderService
    ) {
    }

    ngOnInit() {
    }

    saveFavoris() {
        this._loaderService.show();
        this.customerService.saveFavoris(this.customerService.getCustomer().user.numero_client).then(response => {
            this._loaderService.hide();
            this.customerService.getCustomer().isFavoris = !this.customerService.getCustomer().isFavoris;
        }).catch(
            (response) => {
                this._loaderService.hide();
                this._snackBar.open(response.error.message, 'Close', {
                    duration: 5000
                });
            }
        );
    }
}
