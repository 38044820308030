import {Component, OnInit} from '@angular/core';
import {fromStateSearch} from "../../enums/fromStateSearch";
import {NodeService} from "../../services/node.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-node-clients',
    templateUrl: './node-clients.component.html',
    styleUrls: ['./node-clients.component.scss']
})
export class NodeClientsComponent implements OnInit {

    public fromStateSearch = fromStateSearch;
    public columns: Array<{ id: string, name: string }> = [
        {
            id: 'numero_client',
            name: 'NUMÉRO CLIENT'
        },
        {
            id: 'noeud_1',
            name: 'NOEUD'
        },
        {
            id: 'etat_migration',
            name: 'ÉTAPE MIGRATION'
        }
    ];

    constructor(private _nodeService: NodeService,
                private _router: Router) {
        if (!this._nodeService.currentNode) {
            this._router.navigate(['nodes/stats']);
        }
    }

    ngOnInit() {
    }

}
